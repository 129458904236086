import { admin_project_list as project } from "strings";

export const stageOptions = [
  // Deal Pipeline
  { label: project.active_option, value: "active" },
  { label: project.pitching_option, value: "pitching" },
  { label: project.shadow_pitched_option, value: "shadow_pitched" },
  { label: project.matching_no_support_option, value: "matching_no_support" },
  { label: project.matching_support_option, value: "matching_support" },
  { label: project.sourcing_option, value: "sourcing" },
  { label: project.submitted_option, value: "submitted" },
  { label: project.contracting_option, value: "contracting" },
  { label: project.pipelines_option, value: "pipelines" },
  { label: project.passive_option, value: "passive" },
  { label: project.filled_option, value: "filled" },
  { label: project.lost_option, value: "lost" },
  { label: project.completed_option, value: "completed" },

  // Investor Relations
  { label: project.ir_investor_relationships_option, value: "ir_investor_relationships" },
  { label: project.ir_introduction_option, value: "ir_introduction" },
  { label: project.ir_initial_meeting_option, value: "ir_initial_meeting" },
  { label: project.ir_creating_proposal_option, value: "ir_creating_proposal" },
  { label: project.ir_proposal_sent_option, value: "ir_proposal_sent" },
  { label: project.ir_contract_sent_option, value: "ir_contract_sent" },
  { label: project.ir_project_in_progress_option, value: "ir_project_in_progress" },
  { label: project.ir_lost_option, value: "ir_lost" },
  { label: project.ir_completed_option, value: "ir_completed" },
];
